// Variable overrides
$font-family-sans-serif: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 700;
$enable-shadows: true;
$enable-gradients: true;
$enable-rtl: false;
$input-box-shadow: none;

$card-border-width: 0px;
$card-border-radius: .7em;

$primary-base: #03a9f4;
$primary-100: lighten($primary-base, 100);
$primary-200: lighten($primary-base, 100);
$primary-300: lighten($primary-base, 100);
$primary-400: lighten($primary-base, 100);
$primary-500: lighten($primary-base, 100);
$primary-600: lighten($primary-base, 100);
$primary-700: lighten($primary-base, 100);
$primary-800: lighten($primary-base, 100);
$primary-900: lighten($primary-base, 100);

$primary-dark: darken($primary-base, 25);
$primary: $primary-base;
$primary-50: lighten($primary-base, 50);
$primary-25: lighten($primary-base, 75);
